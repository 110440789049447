<template>
  <div>
    <div class="border-b border-black mb-2 z-0">
      <div class="py-2 mt-3 mx-2">
        <div class="flex items-center justify-between">
          <div class="flex items-center justify-between">
            <svg
              class="fill-current text-white rounded-full bg-gray-400 w-8 h-8 mr-2"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512"
              xml:space="preserve"
            >
              <path
                d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148 C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962 c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216 h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40 c59.551,0,108,48.448,108,108S315.551,256,256,256z"
              />
            </svg>

            <span class="text-sm">Bienvenido {{ me.name }}</span>
          </div>
          <svg
            class="fill-current text-black h-8 w-16"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 220 512 40"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
          >
            <g id="_13-car">
              <g id="linear_color">
                <circle cx="242.8" cy="291.1" r="8.2" />
                <circle cx="438.4" cy="291.1" r="8.2" />
                <path
                  d="M485.5,226.6l-60-9.1l-57.5-47.3c-5.4-4.4-12.1-6.8-19-6.8h-79.6c-9.1,0-17.7,4.1-23.3,11.2l-34.5,43.1h-23.2 c-13.5,0-24.4,11-24.5,24.5v27.2c0,16.5,13.4,29.9,29.9,29.9h14.5c4.5,19,23.5,30.7,42.5,26.2c13-3.1,23.1-13.2,26.2-26.2H404 c4.5,19,23.5,30.7,42.5,26.2c13-3.1,23.1-13.2,26.2-26.2h9.1c13.5,0,24.4-11,24.5-24.5v-24C506.4,238.7,497.5,228.4,485.5,226.6z M357.7,182.8l42.5,34.9h-78.6v-38h27.5C352.2,179.7,355.3,180.8,357.7,182.8z M258.9,184.8c2.6-3.2,6.5-5.1,10.6-5.1h3.1v38 h-40.1L258.9,184.8z M242.8,310.1c-10.5,0-19-8.5-19-19s8.5-19,19-19c10.5,0,19,8.5,19,19C261.8,301.6,253.3,310.1,242.8,310.1z M438.4,310.1c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19C457.4,301.6,448.9,310.1,438.4,310.1z M490,274.8 c0,4.5-3.7,8.1-8.2,8.2h-9.1c-4.5-19-23.5-30.7-42.5-26.2c-13,3.1-23.1,13.2-26.2,26.2H277.1c-4.5-19-23.5-30.7-42.5-26.2 c-13,3.1-23.1,13.2-26.2,26.2h-14.5c-7.5,0-13.6-6.1-13.6-13.6v-27.2c0-4.5,3.7-8.1,8.2-8.2h92.4c4.5,0,8.2-3.6,8.2-8.2v-46.2 h16.3v46.2c0,4.5,3.6,8.2,8.2,8.2h112.5l57.1,8.7c4,0.6,6.9,4,6.9,8.1V274.8z"
                />
                <path
                  d="M324.3,239.5h-10.9c-4.5,0-8.2,3.6-8.2,8.2s3.6,8.2,8.2,8.2h10.9c4.5,0,8.2-3.6,8.2-8.2S328.8,239.5,324.3,239.5z"
                />
                <circle cx="242.8" cy="291.1" r="8.2" />
                <circle cx="438.4" cy="291.1" r="8.2" />
              </g>
            </g>
            <rect x="63.2" y="193.1" width="120.9" height="16.3" />
            <rect x="4" y="234.1" width="120.9" height="16.3" />
            <rect x="29.7" y="275.4" width="120.9" height="16.3" />
          </svg>
        </div>
      </div>
      <div></div>
      <div class="mb-2">
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in mx-2"
        >
          <input
            type="checkbox"
            name="toggle"
            id="toggle"
            @click="
              Checked();
              disponibilidad();
            "
            v-model="checked"
            :checked="checked"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border appearance-none cursor-pointer focus:outline-none"
          />
          <label
            for="toggle"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>
        <label
          for="toggle"
          class="font-bold"
          :class="checked == true ? 'font-bold' : 'line-through'"
          >Disponible</label
        >
      </div>
    </div>
    <div class="flex items-center justify-start z-0">
      <p
        class="flex justify-start font-bold w-44 mt-2 mb-4 ml-2 border-b border-black"
      >
        Lista de pedidos pendientes:
      </p>
    </div>
    <div class="flex items-center justify-center mx-2 z-0">
      <table class="table-auto">
        <thead>
          <tr>
            <th class="border border-black p-1">Orden de compra</th>
            <th class="border border-black p-1">Estado</th>
            <th class="border border-black p-1">Fecha de entrega</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pedido, k) in PedidoVendedorEspera" :key="k">
            <td class="border border-black py-2 text-center">
              <router-link
                class="text-blue-500 underline"
                :to="{
                  name: 'Vpedido',
                  params: {
                    id: pedido.id,
                    name: me.name,
                    hora: pedido.tiempo_estimado,
                    estatus: pedido.estatus,
                    total_factura: pedido.total_factura,
                    comision: me.vendedores[0].comision
                  }
                }"
                >{{ pedido.id.padStart(4, 0) }}</router-link
              >
            </td>
            <td class="border border-black px-1 py-2">
              {{ firstLetterUpperCase(pedido.estatus) }}
            </td>
            <td class="border border-black py-2 text-center">
              {{ formatoFecha(pedido.fecha_entrega) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="
        PedidoVendedorCotizacion != '' ||
          noticacionCotizacionProductoinexistente != ''
      "
      class="flex items-center justify-start mt-2 z-0"
    >
      <p
        class="flex justify-start font-bold w-36 mt-2 mb-3 ml-2 border-b border-black"
      >
        Lista de cotizaciones:
      </p>
    </div>
    <div class="z-0 mx-2">
      <p
        v-if="PedidoVendedorCotizacion != ''"
        class="flex justify-start font-bold mt-2 mb-3 ml-2"
      >
        Completar cotizaciones:
      </p>
      <div
        class="flex items-center font-bold ml-2 justify-between border-b border-black mt-1 w-4/5 py-2"
        v-for="(cotizacion, k) in PedidoVendedorCotizacion"
        :key="k"
      >
        Cotización {{ " " + cotizacion.cotizacion_id.padStart(4, "0") }}
        <router-link
          :to="{
            name: 'Vcotizacion',
            params: { id: cotizacion.cotizacion_id, name: me.name }
          }"
          ><BotonP class="underline" botonpText="Completar"
        /></router-link>
      </div>
      <!--       <p
        v-if="noticacionCotizacionProductoinexistente != ''"
        class="flex justify-start font-bold mt-2 mb-3 ml-2"
      >
        Productos nuevos:
      </p>
      <div
        class="flex items-center font-bold ml-2 justify-between border-b border-black mt-1 w-4/5 py-2"
        v-for="(cotizacion, k) in noticacionCotizacionProductoinexistente"
        :key="k"
      >
        Cotización {{ " " + cotizacion.cotizacion_id }}
        <router-link
          :to="{
            name: 'Vcotizacion',
            params: { id: cotizacion.cotizacion_id, name: me.name }
          }"
          ><BotonP class="underline" botonpText="Completar"
        /></router-link>
      </div> -->
    </div>
    <div class="flex items-center justify-start mt-4 mx-2 z-0">
      <p
        class="flex justify-start font-bold w-36 mt-2 mb-3 ml-2 border-b border-black"
      >
        Lista de pedidos por confirmar:
      </p>
    </div>
    <div class="flex items-center justify-center py-2 mx-2 mb-3 z-0">
      <table class="table-auto">
        <thead>
          <tr class="border-b border-black">
            <th class="p-1">Orden de compra</th>
            <th class="border-r border-l p-1 border-black">Fecha de entrega</th>
            <th class="p-1">Confirmar pedido</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b border-black"
            v-for="(pedidosA, k) in PedidoVendedorAsignado"
            :key="k"
          >
            <td class="py-1 text-center">{{ pedidosA.id.padStart(4, 0) }}</td>
            <td class="py-1 text-center">
              {{ formatoFecha(pedidosA.fecha_entrega) }}
            </td>
            <td class="py-1 text-center">
              <button
                class="mx-2 focus:outline-none"
                @click="confirmarPedido(pedidosA.id, 'espera', k)"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 367.805 367.805"
                  style="enable-background: new 0 0 367.805 367.805"
                  xml:space="preserve"
                  class="h-8 w-8"
                >
                  <g>
                    <path
                      style="fill: #3bb54a"
                      d="M183.903,0.001c101.566,0,183.902,82.336,183.902,183.902s-82.336,183.902-183.902,183.902 S0.001,285.469,0.001,183.903l0,0C-0.288,82.625,81.579,0.29,182.856,0.001C183.205,0,183.554,0,183.903,0.001z"
                    />
                    <polygon
                      style="fill: #d4e1f4"
                      points="285.78,133.225 155.168,263.837 82.025,191.217 111.805,161.96 155.168,204.801 256.001,103.968"
                    />
                  </g>
                </svg>
              </button>
              <button
                class="mx-2 focus:outline-none"
                @click="rechazarPedido(pedidosA.id, k)"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 368.022 368.022"
                  style="enable-background: new 0 0 368.022 368.022"
                  xml:space="preserve"
                  class="h-8 w-8"
                >
                  <g>
                    <path
                      style="fill: #d7443e"
                      d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
                    />
                    <polygon
                      style="fill: #d4e1f4"
                      points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
                    />
                  </g>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import BotonP from "@/components/botonPrimario";
import gql from "graphql-tag";
import { firstLetterUpperCase, formatoFecha } from "@/functions.js";
export default {
  name: "VHome",
  components: {
    BotonP
  },
  data() {
    return {
      Vid: "",
      me: [],
      checked: "",
      pedidosEs: [],
      estatuss: "espera",
      disponible: "",
      PedidoVendedorEspera: [],
      PedidoVendedorAsignado: [],
      PedidoVendedorCotizacion: [],
      noticacionCotizacionProductoinexistente: [],
      idUser: ""
    };
  },
  async mounted() {
    this.$store.state.isLoading = true;
    await this.dataVendedor();

    await window.Echo.private("shoppers." + this.idUser).listen(
      "TipoCambioUpdate",
      e => {
        //Validacion para no repetir mensaje en modal de notificaciones
        //if (e.mensaje.message != this.mensajeAnterior) {

        if (e.mensaje.cotizacion_id != null && e.mensaje.cotizacion_id != "") {
          let duplicadoCotizacion = false;
          for (let i = 0; i < this.PedidoVendedorCotizacion.length; i++) {
            if (
              e.mensaje.cotizacion_id ===
              this.PedidoVendedorCotizacion[i].cotizacion_id
            ) {
              duplicadoCotizacion = true;
              i = this.PedidoVendedorCotizacion.length;
            }
          }
          if (!duplicadoCotizacion) {
            this.PedidoVendedorCotizacion.push({
              cotizacion_id: e.mensaje.cotizacion_id
            });
          }
        }

        if (e.mensaje.compras_id != null && e.mensaje.compras_id != "") {
          this.PedidoVendedorAsignado.push({
            id: e.mensaje.compras_id,
            estatus: "asignado",
            fecha_entrega: e.mensaje.updated_at
          });
        }
      }
    );
    this.$store.state.isLoading = false;
  },
  methods: {
    formatoFecha,
    firstLetterUpperCase,
    async pedidoVendedorCotizacion(idVendedor) {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: gql`
            query PedidoVendedorCotizacion($id: ID!) {
              PedidoVendedorCotizacion(vendedor_id: $id) {
                cotizacion_id
              }
            }
          `,
          variables: {
            id: idVendedor
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          let duplicadoCotizacion = false;
          for (
            let index = 0;
            index < data.data.PedidoVendedorCotizacion.length;
            index++
          ) {
            duplicadoCotizacion = false;
            for (let i = 0; i < this.PedidoVendedorCotizacion.length; i++) {
              if (
                this.PedidoVendedorCotizacion[i].cotizacion_id ===
                data.data.PedidoVendedorCotizacion[index].cotizacion_id
              ) {
                duplicadoCotizacion = true;
              }
            }
            if (!duplicadoCotizacion) {
              this.PedidoVendedorCotizacion.push({
                cotizacion_id:
                  data.data.PedidoVendedorCotizacion[index].cotizacion_id
              });
            }
          }
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },

    async pedidoVendedorAsignado(idVendedor) {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: gql`
            query PedidoVendedorAsignado($id: ID!) {
              PedidoVendedorAsignado(vendedor_id: $id) {
                id
                estatus
                vendedor_id
                tiempo_estimado
                fecha_entrega
              }
            }
          `,
          variables: {
            id: idVendedor
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.PedidoVendedorAsignado = data.data.PedidoVendedorAsignado;
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.$store.state.isLoading = false;
        });
    },

    async pedidoVendedorEspera(idVendedor) {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: gql`
            query PedidoVendedorEspera($id: ID!) {
              PedidoVendedorEspera(vendedor_id: $id) {
                id
                estatus
                vendedor_id
                tiempo_estimado
                fecha_entrega
                total_factura
              }
            }
          `,
          variables: {
            id: idVendedor
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.PedidoVendedorEspera = data.data.PedidoVendedorEspera;
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },

    async notificaciones(idVendedor) {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: gql`
            query noticacionCotizacionProductoinexistente(
              $user_receptor: String!
            ) {
              noticacionCotizacionProductoinexistente(
                user_receptor: $user_receptor
                check: "1"
                producto: "null"
                productoEmptyStringFilter: ""
              ) {
                cotizacion_id
              }
            }
          `,
          variables: {
            user_receptor: idVendedor
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.noticacionCotizacionProductoinexistente =
            data.data.noticacionCotizacionProductoinexistente;
          for (
            let index = 0;
            index < this.noticacionCotizacionProductoinexistente.length;
            index++
          ) {
            for (
              let j = index + 1;
              j < this.noticacionCotizacionProductoinexistente.length;
              j++
            ) {
              if (
                this.noticacionCotizacionProductoinexistente[index]
                  .cotizacion_id ===
                this.noticacionCotizacionProductoinexistente[j].cotizacion_id
              ) {
                this.noticacionCotizacionProductoinexistente.splice(j, 1);
              }
            }
          }
          let agregarNuevaCotizacionNotificacion = true;
          for (
            let i = 0;
            i < this.noticacionCotizacionProductoinexistente.length;
            i++
          ) {
            for (let j = 0; j < this.PedidoVendedorCotizacion.length; j++) {
              if (
                parseInt(
                  this.noticacionCotizacionProductoinexistente[i].cotizacion_id
                ) === parseInt(this.PedidoVendedorCotizacion[j].cotizacion_id)
              ) {
                agregarNuevaCotizacionNotificacion = false;
              }
            }
            if (
              agregarNuevaCotizacionNotificacion &&
              this.noticacionCotizacionProductoinexistente[i].cotizacion_id !=
                null
            ) {
              this.PedidoVendedorCotizacion.push(
                this.noticacionCotizacionProductoinexistente[i]
              );
            }
            this.$store.state.isLoading = false;
            agregarNuevaCotizacionNotificacion = true;
          }
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },

    async dataVendedor() {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: gql`
            query me {
              me {
                name
                id
                vendedores {
                  id
                  disponible
                  comision
                }
              }
            }
          `
        })
        .then(data => {
          this.me = data.data.me;
          if (this.me.vendedores[0].disponible == 0) {
            this.checked = false;
          } else {
            this.checked = true;
          }
          this.idUser = this.me.id;
          this.Vid = this.me.vendedores[0].id;
          this.pedidoVendedorCotizacion(parseInt(this.Vid));
          this.pedidoVendedorEspera(parseInt(this.Vid));
          this.pedidoVendedorAsignado(parseInt(this.Vid));
          this.notificaciones(this.me.id);
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
      //this.dataPedidosCotizacion();
    },
    async disponibilidad() {
      this.$store.state.isLoading = true;
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation($id: ID!, $disponible: String!) {
              actualizarDisponibilidadVendedor(
                id: $id
                input: { disponible: $disponible }
              ) {
                id
              }
            }
          `,
          variables: {
            id: this.Vid,
            disponible: this.disponible
          }
        })
        .then(() => {
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },
    async confirmarPedido(pid, estado, index) {
      this.$store.state.isLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($id: ID!, $data: UpdateEstatusCompra!) {
              editarEstadoPedidoVendedor(id: $id, input: $data) {
                id
              }
            }
          `,
          variables: {
            id: pid,
            data: {
              estatus: estado
            }
          }
        })
        .then(data => {
          this.PedidoVendedorEspera.push({
            id: this.PedidoVendedorAsignado[index].id,
            fecha_entrega: this.PedidoVendedorAsignado[index].fecha_entrega,
            estatus: "espera"
          });
          this.notificarBroadcasting(
            "aceptada por vendedor " + String(this.me.name),
            this.PedidoVendedorAsignado[index].id
          );
          this.PedidoVendedorAsignado.splice(index, 1);
          this.$store.state.isLoading = false;
          alert(
            "Orden de compra # " +
              data.data.editarEstadoPedidoVendedor.id.padStart(4, "0") +
              " aceptada"
          );
        })
        .catch(error => {
          this.message = error;
          this.$store.state.isLoading = false;
          this.showMessage = true;
        });
    },
    rechazarPedido(pid, index) {
      let confirmarRechazo = false;
      let opcion = confirm("¿Esta seguro de rechazar la orden de compra?");
      if (opcion) {
        confirmarRechazo = true;
      } else {
        confirmarRechazo = false;
      }
      if (confirmarRechazo) {
        this.$store.state.isLoading = true;
        this.$apollo
          .mutate({
            mutation: gql`
              mutation($id: ID!, $data: UpdateEstatusCompra!) {
                editarEstadoPedidoVendedor(id: $id, input: $data) {
                  id
                }
              }
            `,
            variables: {
              id: pid,
              data: {
                estatus: "reasignado"
              }
            }
          })
          .then(data => {
            this.$store.state.isLoading = false;
            this.PedidoVendedorAsignado.splice(index, 1);
            this.notificarBroadcasting(
              "RECHAZADA por vendedor " +
                this.firstLetterUpperCase(this.me.name),
              pid
            );
            alert(
              "Orden de compra # " +
                data.data.editarEstadoPedidoVendedor.id +
                " rechazada"
            );
          })
          .catch(error => {
            this.$store.state.isLoading = false;
            this.message = error;
            this.showMessage = true;
          });
      }
    },
    notificarBroadcasting(estatusOrdenCompra, idOrdenCompra) {
      window.axios.post(
        process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
        {
          message:
            "Orden de compra N°" +
            String(idOrdenCompra).padStart(4, "0") +
            " " +
            estatusOrdenCompra +
            ".",
          user_receptor: "G-SU",
          user_emisor: this.me.id,
          compras_id: String(idOrdenCompra),
          check: "1"
        }
      );
    },
    Checked() {
      this.checked = !this.checked;
      this.me.vendedores[0].disponible = this.checked;
      if (this.checked == true) {
        this.disponible = "1";
      } else {
        this.disponible = "0";
      }
    },
    agregarCero(id) {
      id = id.padStart(5, 0);
      return id;
    }
  }
};
</script>

<style></style>
